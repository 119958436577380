import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Alert,
  Card,
  Table,
  Form,
  Spinner,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function albumArt(file) {
  return (
    <OverlayTrigger
      key={file + 'tooltip'}
      placement="top"
      overlay={
        <Tooltip>
          Tip: try making album art with DALL·E 3
        </Tooltip>
      }
    >
      <img src={'music/' + file + '.jpg'} style={{width: '100px'}} />
    </OverlayTrigger>
  )
}

function MusicGen(props) {
  const [status, setStatus] = useState(null);
  const [text, setText] = useState("");
  const [duration, setDuration] = useState(1.0);
  const [jobId, setJobId] = useState(null);
  const [wavUrls, setWavUrls] = useState([]);
  const [jobStatus, setJobStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jobStatusPercentText, setJobStatusPercentText] = useState("");
  const [jobStatusPercent, setJobStatusPercent] = useState(0);

  const getStatus = async () => {
    try {
      const response = await axios.get("/status");
      setStatus(response.data);

      for (let server of response.data.servers) {
        // Try to parse the job_status as JSON, and handle any exceptions that might occur
        try {
          let jobStatus = server.job_status;
          if (jobStatus.job_id == jobId) {
            // Extract status text from the string (after the last '\r')
            setJobStatusPercentText(jobStatus.status);
            setJobStatusPercent(jobStatus.percent)
            break; // Break out of loop once a match is found
          }
        } catch (e) {
          console.error("Error parsing job status:", e);
        }
      }
    } catch (error) {
      // console.error("Error fetching status:", error); // Log the error for debugging
      // Optionally, you can set an error state to show a user-friendly message on the UI
      setStatus({ error: true, message: "Failed to fetch status." });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getStatus(jobId), 800);
    return () => clearInterval(interval);
  }, [jobId, getStatus]);

  useEffect(() => {
    console.log(props.activeTab)
    if (props.activeTab === "musicgen") {
      if (wavUrls?.length < 1) {
        const savedMessages = localStorage.getItem("musicgen-urls");

        if (savedMessages) {
          try {
            let parsed = JSON.parse(savedMessages);
            if (parsed) {
              console.log(parsed);
              setWavUrls(parsed);
            }
          } catch {
            console.log("Failed to parse saved music.");
          }
        }
      }
    }
  }, [props.activeTab]);

  function saveToLocalStorage(newUrls) {
    try {
      localStorage.setItem("musicgen-urls", JSON.stringify(newUrls));
    } catch (e) {
      console.error("An error occurred while saving to Local Storage", e);
    }
  }

  const submitJob = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setJobStatusPercentText("");
    setJobStatusPercent(0);
    setJobStatus(null);
    setWavUrls([...wavUrls, { 'prompt': text, 'url': null }])
    try {
      const response = await axios.post("/submit", { text, duration });
      setJobId(response.data.job_id);
    } catch (error) {
      console.error("Error submitting:", error); // Log the error for debugging
      // Optionally, you can set an error state to show a user-friendly message on the UI
      setStatus({ error: true, message: "Failed to submit job." });
    }
  };

  const checkJobStatus = useCallback(async () => {
    if (!jobId) return;
    if (jobStatus == "finished") return;
    const response = await axios.get(`/job-status/${jobId}`);
    setJobStatus(response.data.status);
    if (response.data.status === "finished") {
      let lastEntry = null
      let url = `https://storage.googleapis.com/music-gen-stageone/${jobId}.wav`
      if (wavUrls.length > 0) {
        lastEntry = { 'prompt': wavUrls[wavUrls.length - 1]['prompt'], 'url': url }
      } else {
        lastEntry = { 'prompt': '', 'url': url }
      }
      let newUrls = [...wavUrls.slice(0, wavUrls.length - 1), lastEntry]
      setWavUrls(newUrls)
      setIsSubmitting(false); // stop the spinner when the job is finished
      setJobStatusPercentText("");
      setJobStatusPercent(1);
      saveToLocalStorage(newUrls)
    } else if (response.data.status === "failed") {
      setIsSubmitting(false);
      setJobStatusPercent(0);
    } else {
      setIsSubmitting(true); // keep the spinner running while the job is active
    }
  }, [jobId, jobStatus]);

  useEffect(() => {
    const interval = setInterval(checkJobStatus, 500);
    return () => clearInterval(interval);
  }, [checkJobStatus]);

  function reset() {
    setWavUrls([])
    localStorage.setItem("musicgen-urls", JSON.stringify([]));
  }

  let mp3Table = <></>

  let mp3Rows = []
  for (let i = 0; i < wavUrls.length; i++) {

    if (wavUrls[i]['url'] != null) {
      mp3Rows.push(
        <tr key={'mp3row' + i}>
          <td style={{ textAlign: 'center' }}>{i + 1}</td>
          <td>
            <div style={{ display: 'flex', gap: "10px", alignItems: 'center', height: '100%' }}>
              <audio controls src={wavUrls[i]['url']} className="w-100" />
              <span>(<a href={wavUrls[i]['url']}>.wav</a>)</span>
            </div>
          </td>
          <td>{wavUrls[i]['prompt']}</td>
        </tr>
      )
    }
  }

  let reset_button = <></>;
  if (mp3Rows.length > 0) {
    reset_button = (
      <div style={{ textAlign: "right" }}>
        <Button variant="outline-secondary" onClick={reset}>
          Clear Music
        </Button>
      </div>
    );
  }

  if (mp3Rows.length < 1) {
    mp3Rows.push(
      <tr key={'nomusic'}>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
    )
  }

  mp3Table = (
    <Row className="mb-4">
      <Col>
        <Card>
          <Card.Header as="h4"><div style={{ display: 'flex', justifyContent: 'space-between' }}>Generated Music {reset_button}</div></Card.Header>
          <Card.Body>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ minWidth: '300px' }}>Music</th>
                  <th>Prompt</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: 'middle' }}>
                {mp3Rows}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )

  return (
    <Container className="mt-4">
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header as="h4">Music Generation</Card.Header>
            <Card.Body>
              <Form onSubmit={submitJob}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Describe the music you want with instruments and sound..."
                    aria-label="Music Text"
                    aria-describedby="basic-addon2"
                    value={text}
                    as="textarea"
                    rows={3}
                    onChange={(e) => setText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        submitJob(e);
                      }
                    }}
                  />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </InputGroup>
                <Form.Group controlId="duration">
                  <Form.Label>Duration (seconds): {duration}</Form.Label>
                  {/* <Form.Control
                    className="custom-range"
                    type="range"
                    min="1.0"
                    max="30.0"
                    step="0.1"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  /> */}
                  <RangeSlider
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    min="1.0"
                    max="30.0"
                    step="0.1"
                  />
                </Form.Group>
              </Form>
              {status && status.message && (
                <Alert variant="info" className="mt-3">
                  Status: {status.message}
                </Alert>
              )}
              {(jobStatus || jobStatusPercentText) && (
                <Alert variant="info" className="mt-3">
                  {jobStatus && (
                    <>
                      Job Status: {jobStatus}
                      <br />
                    </>
                  )}
                  {jobStatusPercentText && (
                    <>Progress: {jobStatusPercentText}</>
                  )}
                </Alert>
              )}
              {(jobStatusPercent && jobStatusPercent) > 0 && (
                <div>
                  <h5>Music Generation Progress</h5>
                  <ProgressBar
                    variant="info"
                    now={jobStatusPercent * 100}
                    label={`${Math.round(jobStatusPercent * 100)}%`}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {mp3Table}

      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header as="h4">Examples</Card.Header>
            <Card.Body>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Genre</th>
                    <th style={{textAlign: 'center'}}>Album Art</th>
                    <th style={{ minWidth: '300px' }}>Music</th>
                    <th>Prompt</th>
                  </tr>
                </thead>
                <tbody style={{ verticalAlign: 'middle' }}>
                  <tr>
                    <td>Acoustic</td>
                    <td style={{textAlign: 'center'}}>{albumArt("Acoustic_Album")}</td>
                    <td><audio controls src="music/Acoustic_Song_10sec.wav" className="w-100" /></td>
                    <td>An acoustic melody featuring the gentle strumming of an acoustic guitar, the soft resonance of a piano, and the subtle rhythm of a cajón, creating a harmonious and serene soundscape.</td>
                  </tr>
                  <tr>
                    <td>Rock</td>
                    <td style={{textAlign: 'center'}}>{albumArt("Rock_Album")}</td>
                    <td><audio controls src="music/Rock_Song_10sec.wav" className="w-100" /></td>
                    <td>An energetic rock riff, driven by the powerful strums of an electric guitar, the pounding beats of a drum set, and the deep grooves of a bass guitar, creating an electrifying and intense musical burst.</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td style={{textAlign: 'center'}}>{albumArt("Country_Album")}</td>
                    <td><audio controls src="music/Country_Song_10sec.wav" className="w-100" /></td>
                    <td>A country music clip featuring a lively blend of a twanging banjo, a rhythmic acoustic guitar, and a soulful harmonica, encapsulating the heartwarming and rustic essence of country music.</td>
                  </tr>
                  <tr>
                    <td>Electronic</td>
                    <td style={{textAlign: 'center'}}>{albumArt("Electronic_Album")}</td>
                    <td><audio controls src="music/Electronic_Song_10sec.wav" className="w-100" /></td>
                    <td>An electronic music snippet, characterized by synthesized beats, a pulsating bassline, and digital sound effects, creating a futuristic and high-energy auditory experience.</td>
                  </tr>
                  <tr>
                    <td>Hip hop</td>
                    <td style={{textAlign: 'center'}}>{albumArt("Hip-Hop_Album")}</td>
                    <td><audio controls src="music/HipHop_Song_10sec.wav" className="w-100" /></td>
                    <td>A hip-hop beat featuring a deep bassline, crisp snare drums, and rhythmic hi-hats, overlaid with samples or scratches, embodying the urban and expressive vibe of hip-hop.</td>
                  </tr>
                  <tr>
                    <td>Latin</td>
                    <td style={{textAlign: 'center'}}>{albumArt("Latin_Album")}</td>
                    <td><audio controls src="music/Latin_Song_10sec.wav" className="w-100" /></td>
                    <td>A Latin music excerpt with vibrant rhythms from congas and bongos, melodic accents from classical guitar, and the energetic pulse of a brass section, capturing the lively and passionate spirit of Latin music.</td>
                  </tr>
                  <tr>
                    <td>Lo-Fi</td>
                    <td style={{textAlign: 'center'}}>{albumArt("lofi_Album")}</td>
                    <td><audio controls src="music/lofi_Song_10sec.wav" className="w-100" /></td>
                    <td>A lo-fi track featuring a mellow, downtempo beat created with soft electronic drums, a relaxed, looping keyboard melody, and subtle vinyl crackles, evoking a calm and introspective atmosphere.</td>
                  </tr>
                  <tr>
                    <td>Reggae</td>
                    <td style={{textAlign: 'center'}}>{albumArt("Reggae_Album")}</td>
                    <td><audio controls src="music/Reggae_Song_10sec.wav" className="w-100" /></td>
                    <td>A reggae snippet with a laid-back groove from a rhythmic bass guitar, offbeat chords played on a clean electric guitar, and steady drumbeats, all creating a chill and sunny reggae vibe.</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header as="h4">Cluster Status</Card.Header>
            <Card.Body>
              {status ? (
                <>
                  <Alert variant="success">
                    Online Servers: {status.online_servers}
                  </Alert>
                  <Table responsive="sm" striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>GPU Name</th>
                        <th>Utilization</th>
                        <th>Memory Total</th>
                        <th>Memory Used</th>
                        {/* <th>Disk Used</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {status.servers &&
                        status.servers.map((server, index) => (
                          <tr key={server.name}>
                            <td>{index + 1}</td>
                            <td>{server.gpu_status.gpu_name}</td>
                            <td>{server.gpu_status.gpu_utilization}</td>
                            <td>{server.gpu_status.memory_total}</td>
                            <td>{server.gpu_status.memory_used}</td>
                            {/* <td>{server.gpu_status.used_space_gb}</td> */}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <Alert variant="info">Loading...</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export { MusicGen };
