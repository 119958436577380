import React, { useState, useEffect, useMemo, useRef } from "react";

import {
  Container,
  Row,
  Col,
  Tabs,
  Form,
  InputGroup,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';


import "./App.css";
import { Gpt } from "./Gpt";
import { Dalle } from "./Dalle";
import { MusicGen } from "./MusicGen";
import { ResetAllButton } from "./ResetAllButton";


import FormControl from "react-bootstrap/FormControl";

function App() {

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [authenticationFailed, setAuthenticationFailed] = React.useState(false);
  const [openaiApiKeyGpt4, setOpenaiApiKeyGpt4] = React.useState('');
  const [gptModel, setGptModel] = React.useState('');
  const [openaiApiKeyDalle3, setOpenaiApiKeyDalle3] = React.useState('');
  const [nodeId, setNodeId] = React.useState(-1);
  const [nodeIdDone, setNodeIdDone] = React.useState(false);
  const [moviePosterUrls1, setMoviePosterUrls1] = React.useState({});
  const [moviePosterUrls2, setMoviePosterUrls2] = React.useState({});
  const [workshopName1, setWorkshopName1] = React.useState('');
  const [workshopName2, setWorkshopName2] = React.useState('');
  const [myWorkshopNumber, setMyWorkshopNumber] = React.useState(-1);

  const [activeTab, setActiveTab] = useState('gpt');


  const passwordRef = useRef(null);
  const nodeTypeRef = useRef(null);
  const nodeIdRef = useRef(null);
  const workshopNameRef = useRef(null);


  useEffect(() => {
    const storedPassword = localStorage.getItem("frontend-password");

    const storedNode = JSON.parse(localStorage.getItem("nn-node"));
    if (storedNode) {
      setNodeId(storedNode);
      setNodeIdDone(true);
    }

    if (storedPassword) {
      authenticate(storedPassword);
    }

  }, []);

  function resetAll() {
    localStorage.setItem("gpt-messages", JSON.stringify([]));
    localStorage.setItem("dalle-outputs", JSON.stringify([]));
    localStorage.setItem("musicgen-urls", JSON.stringify([]));
    localStorage.setItem("nn-node", JSON.stringify(null));
    window.location.reload();
  }

  function authenticate(passwordValue) {

    fetch("/authenticate_frontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: passwordValue }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.authenticated) {
          // Handle successful authentication
          console.log("Authenticated successfully");
          setIsAuthenticated(true);
          setAuthenticationFailed(false);

          setOpenaiApiKeyGpt4(data.openai_api_key_gpt4)
          setOpenaiApiKeyDalle3(data.openai_api_key_dalle3)

          setGptModel(data.gpt_model)

          setMoviePosterUrls1(data.movie_poster_urls1)
          setMoviePosterUrls2(data.movie_poster_urls2)

          setWorkshopName1(data.workshop_name1)
          setWorkshopName2(data.workshop_name2)

          // Save password in local storage
          localStorage.setItem("frontend-password", passwordValue);

        } else {
          // Handle failed authentication
          console.log("Authentication failed");
          setIsAuthenticated(false);
          setAuthenticationFailed(true);
        }
      })
      .catch((error) => {
        console.error(
          "There was an error with the authentication request:",
          error
        );
      });
  }

  let errorMessageToast = <></>;
  if (errorMessage) {
    errorMessageToast = (
      <ToastContainer position="bottom-center" containerPosition="fixed">
        <Toast
          show={errorMessage != null}
          onClose={() => setErrorMessage(null)}
          style={{ marginBottom: "10px", backgroundColor: "#dddddd" }}
        >
          <Toast.Header>
            <strong className="me-auto" style={{ fontSize: 'large' }}>Error</strong>
          </Toast.Header>
          <Toast.Body style={{ textAlign: "center" }}>
            {errorMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    );
  }

  let failedText = <></>
  if (authenticationFailed) {
    failedText = <><br /><span style={{ color: 'red' }}>Authentication failed</span></>
  }

  if (!isAuthenticated) {
    return (
      <Container className="mt-4">
        <Row className="mb-4">
          <Col>
            <h2>Enter today's password</h2>
            <br />
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                const passwordValue = passwordRef.current.value;
                authenticate(passwordValue);
              }}
            >
              <InputGroup>
                <FormControl
                  type="password"
                  placeholder=""
                  aria-label="Password"
                  aria-describedby="password-addon"
                  ref={passwordRef}
                  autoFocus
                  key="passbox"
                  style={{ fontSize: '150%' }}
                />
                <Button type="submit">Submit</Button>
              </InputGroup>
            </Form>
            {failedText}
          </Col>
        </Row>
        {errorMessageToast}
        <a href="manage-servers" className="gear-icon">
          <FontAwesomeIcon icon={faCog} />
        </a>
      </Container>
    )
  }

  if (!nodeIdDone) {
    let workshopNameSelect = <></>;
    if (workshopName2 !== '') {
      workshopNameSelect = (<Form.Select aria-label="Node type select"
        style={{ maxWidth: '300px', marginRight: '10px' }}
        ref={workshopNameRef}>
        <option value="">Select Workshop ID...</option>
        <option value="1">{workshopName1}</option>
        <option value="2">{workshopName2}</option>
      </Form.Select>)
    }
    return (
      <Container className="mt-4">
        <Row className="mb-4">
          <Col>
            <h2>My neural network node was:</h2>
            <p>(the node you had during the paper exercise)</p>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                let workshopNum = '1'
                if (workshopName2 !== '') {
                  workshopNum = workshopNameRef.current.value;
                }
                const nodeval = nodeIdRef.current.value;
                const nodetype = nodeTypeRef.current.value;
                if (workshopNum === "" || nodeval === "" || nodetype === "") {
                  setErrorMessage("Select a workshop ID and enter a node number.");
                  return;
                }
                setMyWorkshopNumber(workshopNum);
                setNodeId(nodetype + nodeval);
                setNodeIdDone(true);
                localStorage.setItem("nn-node", JSON.stringify(nodetype + nodeval));
              }}
            >
              <InputGroup>
                {workshopNameSelect}
                <Form.Select aria-label="Node type select"
                  style={{ maxWidth: '100px', marginRight: '10px' }}
                  ref={nodeTypeRef}>
                  <option value="B">B</option>
                  <option value="C">C</option>
                </Form.Select>
                <Form.Control
                  type="number"
                  placeholder="Enter number"
                  min="0"
                  max="100"
                  style={{ maxWidth: '200px' }}
                  ref={nodeIdRef}
                />
                <Button type="submit">Go</Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>
        {errorMessageToast}
      </Container>

    )
  }

  let gptNumber = '4';
  if (gptModel.includes('gpt-3.5')) {
    gptNumber = '3.5'
  }

  let moviePosterUrlsOut = moviePosterUrls1;
  if (myWorkshopNumber === '2') {
    moviePosterUrlsOut = moviePosterUrls2;
  }

  return (
    <Container className="mt-4">

      <Row className="mb-4">
        <Col>
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f05f40', padding: '8px', borderRadius: '8px' }}>
            {/* <span style={{ fontSize: 'xx-large', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', color: 'white' }}>
              STAGE ONE EDUCATION
            </span>
            <span style={{ fontSize: 'x-large', fontFamily: 'Roboto, sans-serif', color: 'white', marginLeft: '5px' }}>
              — Hands-on Engineering Workshops
            </span> */}
            <img src="/logo.svg" alt="Stage One Education | Hands on Engineering Workshop" style={{ height: '75px', padding: '5px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <br />
          <span style={{ fontSize: 'xx-large', color: '#444' }}>AI Film Studio | Artificial Intelligence Workshop</span>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="blue-tabs"
          >
            <Tab eventKey="gpt" title={<>ChatGPT{gptNumber} | <span style={{ fontSize: 'small' }}> Text generation</span></>}>
              <Gpt
                openaiApiKey={openaiApiKeyGpt4}
                gptModel={gptModel}
                setErrorMessage={setErrorMessage}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </Tab>
            <Tab eventKey="dalle" title={<>DALL·E 3 | <span style={{ fontSize: 'small' }}> Image generation</span></>}>
              <Dalle
                openaiApiKey={openaiApiKeyDalle3}
                setErrorMessage={setErrorMessage}
                activeTab={activeTab}
                nodeId={nodeId}
                moviePosterUrls={moviePosterUrlsOut}
              />
            </Tab>
            <Tab eventKey="musicgen" title={<>Music Gen | <span style={{ fontSize: 'small' }}> Music generation</span></>}>
              <MusicGen
                activeTab={activeTab}
              />
            </Tab>

          </Tabs>
          <Tab.Container activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="gpt"><>ChatGPT{gptNumber} | <span style={{ fontSize: 'small' }}> Text generation</span></></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="dalle"><>DALL·E 3 | <span style={{ fontSize: 'small' }}> Image generation</span></></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="musicgen"><>Music Gen | <span style={{ fontSize: 'small' }}> Music generation</span></>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>

          <div style={{ textAlign: 'right' }}>
            <ResetAllButton
              callback={resetAll}
            />
          </div>
        </Col>
      </Row>
      {errorMessageToast}
      <a href="manage-servers" className="gear-icon">
        <FontAwesomeIcon icon={faCog} />
      </a>
    </Container>
  );
}

export default App;
