import React, { useState, useEffect, useMemo, useRef } from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import Markdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { getEncoding, encodingForModel } from "js-tiktoken";

import { SSE } from "sse";

const TOKEN_LIMITS = {
  "gpt-4": 8000,
  "gpt-4-turbo-preview": 120000,
  "gpt-3.5-turbo": 4050,
};

const filmIdeaPrompt1 = "List 5 ideas for a short film about ";
const filmIdeaPrompt2 =
  ". Include character development and explain why the film is different from the tropes of the genre.";

const filmIdeas = [
  "dragons and dwarfs: mountain siege",
  "mars colony: rebel uprising",
  "love letters across time",
  "haunted lighthouse: island mystery",
  "mismatched roommates: hilarious chaos",
  "heist at the cyberbank",
  "family secrets: ancestral home",
  "whispering shadows: unsolved crimes",
  "jungle quest for lost city",
  "revolution: the untold story",
  "time loop in ancient rome",
  "virtual world escape",
  "undercover in 1920s paris",
  "alien artifact discovery",
  "pirates of the forgotten seas",
  "cyberpunk city rebellion",
  "haunted victorian mansion",
  "wild west robot showdown",
  "post-apocalyptic road journey",
  "deep sea alien encounter",
  "medieval kingdom betrayal",
  "lost in a digital universe",
  "celebrity life undercover",
  "arctic expedition survival",
  "ancient egyptian curse",
  "parallel lives intersect",
  "desert oasis mirage",
  "robotic uprising in 2077",
  "summer camp secrets",
  "moon colony conspiracy",
];

function Gpt(props) {
  const [messages, setMessages] = React.useState([]);
  const [hasStarted, setHasStarted] = React.useState(false);
  const [isStreaming, setIsStreaming] = React.useState(false);
  const [promptNum, setPromptNum] = React.useState(0);
  const [lastPromptRun, setLastPromptRun] = React.useState(-1);
  const [showAfter, setShowAfter] = React.useState(-1);
  const [showScroll, setShowScroll] = useState(false);

  const prompts = [
    {
      title: "Film idea",
      helpText: "",
      fillIn: "FILL IN HERE",
      promptVal: [
        "List 5 ideas for a short film about ",
        ". Include character development and explain why the film is different from the tropes of the genre. Use a style that grabs and holds the attention of readers.",
      ],
      showAfter: (
        <>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                backgroundColor: "white",
                fontSize: "large",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              Choose the film title you like the most and select{" "}
              <span style={{ color: "black" }}>
                <a href="#!" onClick={() => handlePromptButtonClicked(null, 1)}>
                  2: Script Outline
                </a>
              </span>{" "}
              to further develop your film.
              <br />
              <br />
              Don't see a title that fits? No problem! Instead adjust the
              current prompt to help the AI better understand your film and
              generate the perfect film idea.
            </p>
          </div>
          <hr />
        </>
      ),
    },
    {
      title: "Script outline",
      fillIn: "FILL IN YOUR FAVORITE TITLE FROM FILM IDEA",
      promptVal: [
        "Movie title: ",
        "<br>Make a detailed outline for 5 scenes in a short film. Include a list of characters and a list of scenes.",
      ],
      showAfter: (
        <>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                backgroundColor: "white",
                fontSize: "large",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              AI tools like text generation work best with specific information.
              Let's start by generating the script for Scene 1. Once that's
              complete, you can move on to generate the rest of your scenes.
            </p>
          </div>
          <hr />
        </>
      ),
    },
    {
      title: "Generate a script",
      fillIn: "1",
      promptVal: [
        "Write a complete script for Scene ",
        ".  Include everything needed to film the scene, such as prompts, description of the set, and a complete script with dialogue.",
      ],
      showAfter: (
        <>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                backgroundColor: "white",
                fontSize: "large",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              Next, let's create a short description of your film. Be prepared
              to present this text to the class.
            </p>
          </div>
          <hr />
        </>
      ),
    },
    {
      title: "Elevator pitch",
      fillIn: "«FILL IN HERE»",
      promptVal: [
        "Describe a 30 word elevator pitch for the film. Include the climax, rising and falling actions. Write in the style of a film trailer.",
      ],
      showAfter: <></>,
    },
    {
      title: "Marketing materials",
      fillIn: "«FILL IN HERE»",
      promptVal: [
        "Make me a list of marketing materials I should create for the film.",
      ],
      showAfter: <></>,
    },
    {
      title: "Movie poster",
      fillIn: "«FILL IN HERE»",
      promptVal: [
        "Describe a film poster in exquisite detail for this movie. Include the style of the art. Generate 4 options.",
      ],
      showAfter: (
        <>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                backgroundColor: "white",
                fontSize: "large",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              <span>Now generate your movie poster image!</span>
              <Button
                className="stageone-btn"
                onClick={() => props.setActiveTab("dalle")}
              >
                Go to image generation
              </Button>{" "}
            </p>
          </div>
          <hr />
        </>
      ),
      showAfter: <></>,
    },
    {
      title: "Music",
      fillIn: "1",
      promptVal: [
        "Describe music for scene ",
        " in a few sentences.  Be detailed about which instruments and styles are present.",
      ],
      showAfter: <></>,
    },
    {
      title: "Production budget",
      fillIn: "«FILL IN HERE»",
      promptVal: [
        "I want you to create a complete budget for the filming. Produce that budget step-by-step, include estimated prices, and include links for where I can buy things.",
      ],
      showAfter: <></>,
    },
    {
      title: "Convert budget to spreadsheet",
      fillIn: "1",
      promptVal: [
        "Reproduce that output in a summary form as .csv text. Include the description as another column.",
      ],
      showAfter: <></>,
    },
    {
      title: "Insurance",
      fillIn:
        "It's time for YOU to write your own prompt!  Ask GPT about what insurance you'll need for the film.",
      promptVal: ["", ""],
      showAfter: <></>,
    },
    {
      title: "List of equipment to rent",
      fillIn:
        "It's time for YOU to write your own prompt!  Ask GPT about what you'll need for the film.",
      promptVal: ["", ""],
      showAfter: <></>,
    },
    {
      title: "Casting call",
      fillIn:
        "It's time for YOU to write your own prompt!  Ask GPT to help you make a casting call for the film.",
      promptVal: ["", ""],
      showAfter: <></>,
    },
    {
      title: "Day-of shooting schedule",
      fillIn:
        "It's time for YOU to write your own prompt!  Ask GPT to make a day-of shooting schedule.",
      promptVal: ["", ""],
      showAfter: <></>,
    },
    {
      title: "Social media post",
      fillIn:
        "It's time for YOU to write your own prompt!  Ask GPT to help you with some social media posts.",
      promptVal: ["", ""],
      showAfter: (
        <>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                backgroundColor: "white",
                fontSize: "large",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              Your film needs a launch website. Let’s ask GPT-4 to program a
              html-only website.
            </p>
          </div>
          <hr />
        </>
      ),
    },
    {
      title: "Website",
      fillIn: "FILL IN",
      promptVal: [
        "Movie Title: ",
        `<br />
      Description: FILL IN<br />
      Starring: FILL IN<br />
      Write a simple HTML landing page website for launching the movie.  Use nice formatting, text colors, and fonts.  Make a spot for us to put the movie poster as a .png, 600 pixels wide.  Also add more content subsections and links to other parts of the website as well as external links (like where can I buy tickets).`,
      ],
      showAfter: (
        <>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                fontSize: "large",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              <ul>
                <li>Copy the .html code → paste code into notepad</li>

                <li>Save notepad on desktop as: MOVIE_TITLE_website.html</li>

                <li>
                  Double-click on the saved file to view it locally in your
                  browser.
                </li>

                <li>
                  You may need to rename your movie poster .png so it can show
                  up in the website.
                </li>

                <li>HTML can only render a “static” website. </li>
                <li>
                  One way to make it dynamic is by adding Javascript, by just
                  asking GPT:
                </li>
                <code>
                  Can you add Javascript code so that when you hover over the
                  movie poster, it turns black and white?
                </code>
                <li>What other website features can you add?</li>
              </ul>
            </div>
          </div>
          <hr />
        </>
      ),
    },
  ];

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    // return () => window.removeEventListener("scroll", checkScrollTop);
  }, []);

  useEffect(() => {
    if (props.activeTab === "gpt") {
      if (messages?.length < 1) {
        handlePromptButtonClicked(null, 0);

        if (!hasStarted) {
          const savedMessages = localStorage.getItem("gpt-messages");

          if (savedMessages) {
            try {
              let parsed = JSON.parse(savedMessages);
              if (parsed) {
                console.log(parsed);
                setMessages(parsed);
                setHasStarted(true);
              }
            } catch {
              console.log("Failed to parse saved messages.");
            }
          }
        }
      } else {
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "instant",
          });
        }, 50);
      }
    }
  }, [props.activeTab]);

  const requestId = React.useRef(0);
  const questionTextRef = useRef(null);

  let currentStreamId = useRef(null);
  let ignoreStreamId = useRef(null);

  let spinnerDisp = "";

  let mainSummary = <></>;
  if (messages !== undefined && messages.length > 0) {
    mainSummary = <MessagesDisplay messages={messages} />;
    spinnerDisp = "none";
  }

  let chatBoxTopMargin = hasStarted ? "20px" : "";

  let respDisp = hasStarted ? "" : "none";
  const stopVis = isStreaming ? "visible" : "hidden";

  let gptName = "GPT4";
  if (props.gptModel.includes("gpt-3.5")) {
    gptName = "GPT3.5";
  }

  return (
    <Container className="my-4">
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header
              as="h4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Chat with {gptName}
              {messages?.length > 1 && (
                <>
                  <Button variant="outline-secondary" onClick={reset}>
                    Clear all messages
                  </Button>
                </>
              )}
            </Card.Header>

            <Card.Body>
              <h4 style={{ color: "#f05f40ff", fontWeight: "bold" }}>
                Follow the prompt templates to bring your film to life with AI.
              </h4>
              <p style={{ color: "#f05f40ff", fontWeight: "bold" }}>
                Remember: apply prompt engineering to enhance and personalize
                the templates.
              </p>

              <section
                className="response-section"
                style={{ display: respDisp }}
              >
                {mainSummary}
              </section>
              <section
                className="input-section"
                style={{ marginTop: chatBoxTopMargin }}
              >
                <Form
                  onSubmit={(e) => {
                    onSubmit(e);
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    {generatePromptButtons()}
                    {generateHelpText()}
                  </div>
                  <hr style={{ marginTop: "40px" }} />
                  Prompt for {gptName}:
                  <InputGroup>
                    <div
                      onInput={checkIfEmpty}
                      className="form-control orange-highlight"
                      contentEditable={!isStreaming}
                      ref={questionTextRef}
                      data-placeholder={
                        "Type your message to " + gptName + "..."
                      }
                      onKeyDown={checkForCtrlEnter}
                      style={{ minHeight: "6.5em" }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Button
                        variant="danger"
                        style={{
                          visibility: stopVis,
                          width: "4em",
                          height: "3em",
                        }}
                        onClick={handleStopButton}
                      >
                        Stop
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        style={{
                          marginTop: "auto",
                          width: "4em",
                          height: "3em",
                        }}
                        disabled={isStreaming}
                      >
                        Go
                      </Button>
                    </div>
                  </InputGroup>
                </Form>

                {messages?.length > 1 && (
                  <>
                    <br />
                    <Button
                      variant="outline-secondary"
                      style={{ marginTop: "20px" }}
                      onClick={reset}
                    >
                      Clear all messages
                    </Button>
                  </>
                )}
              </section>
            </Card.Body>
          </Card>
          <Button
            onClick={scrollTop}
            style={{
              display: showScroll ? "flex" : "none",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: "1000",
            }}
            variant="secondary"
          >
            ↑
          </Button>
        </Col>
      </Row>
    </Container>
  );

  function generateHelpText() {
    if (prompts[promptNum] && "helpText in prompts[promptNum]") {
      return <>{prompts[promptNum]["helpText"]}</>;
    }
    return <></>;
  }

  function generatePromptButtons() {
    const promptButtonStyle = { marginRight: "10px", marginBottom: "15px" };
    let out = [];
    for (let i = 0; i < prompts.length; i++) {
      let variant = "outline-secondary";
      let btnClass = "";
      if (promptNum == i) {
        variant = "outline-primary";
      }
      if (lastPromptRun + 1 == i && !isStreaming) {
        btnClass = "animated-outline";
      }
      out.push(
        <Button
          variant={variant}
          style={promptButtonStyle}
          key={i}
          onClick={(e) => handlePromptButtonClicked(e, i)}
          className={btnClass}
        >
          {i + 1}. {prompts[i]["title"]}
        </Button>
      );
    }
    return out;
  }

  function handlePromptButtonClicked(e, index) {
    if (index < 0 || index >= prompts.length) {
      console.error("Invalid index");
      return;
    }

    setPromptNum(index);

    let htmlContent = "";
    let fillInPosition;

    const randomNumber = Math.floor(Math.random() * filmIdeas.length);

    let fillInStr = prompts[index]["fillIn"];
    if (index == 0) {
      fillInStr = `FILL IN HERE, example: "${filmIdeas[randomNumber]}"`;
    }
    // fillInStr='<span style="font-size:120%; color: #f05f40">' + fillInStr + '</span>';

    const values = prompts[index]["promptVal"];
    if (values.length === 2) {
      // Store the start position of "FILL IN"
      fillInPosition = values[0].length;
      // Insert "FILL IN" between the two values
      htmlContent = `${values[0]}${fillInStr}${values[1]}`;
    } else {
      // Single value case
      htmlContent = values[0];
    }

    questionTextRef.current.innerHTML = htmlContent;

    // Automatically highlight "FILL IN" if it exists
    if (fillInPosition !== undefined) {
      highlightText(fillInPosition, fillInStr.length);
    }

    // questionTextRef.current.innerHTML = `${filmIdeaPrompt1}<strong>${e.target.value}</strong>${filmIdeaPrompt2}`
    // setFilmIdeaPrompt()
  }

  function highlightText(startPosition, length) {
    const range = document.createRange();
    const selection = window.getSelection();

    range.setStart(questionTextRef.current.firstChild, startPosition);
    range.setEnd(questionTextRef.current.firstChild, startPosition + length);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  // function highlightText(startPosition, length) {
  //   const range = document.createRange();
  //   const selection = window.getSelection();
  //   const node = questionTextRef.current.childNodes[0]; // Assuming the first child is the text node, this might need adjustment

  //   try {
  //     range.setStart(node, startPosition);
  //     range.setEnd(node, startPosition + length);
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   } catch (error) {
  //     console.error('Error highlighting text:', error);
  //   }
  // }

  function saveMesssagesToLocalStorage(newMessages) {
    try {
      localStorage.setItem("gpt-messages", JSON.stringify(newMessages));
    } catch (e) {
      console.error("An error occurred while saving to Local Storage", e);
    }
  }

  function checkIfEmpty() {
    if (questionTextRef.current.innerHTML === "<br>") {
      questionTextRef.current.innerHTML = "";
    }
  }

  function checkForCtrlEnter(e) {
    if (!e.ctrlKey && !e.shiftKey && e.code == "Enter") {
      onSubmit(e);
    }
  }

  function reset() {
    setHasStarted(false);
    requestId.current += 1;
    setMessages([]);
    localStorage.setItem("gpt-messages", JSON.stringify([]));
    questionTextRef.current.focus();
  }

  function MessagesDisplay(props) {
    let counter = 0;
    let out = [];
    for (let message of props.messages) {
      out.push(<SingleMessageDisplay message={message} key={counter} />);
      counter += 1;
    }

    return (
      <div>
        <div>{out}</div>
      </div>
    );
  }

  function limitMessagesByTokenCount(messages, tokenLimit) {
    const enc = encodingForModel("gpt-4");

    let totalTokens = 0;
    let processed = [];

    for (let i = messages.length - 1; i >= 0; i--) {
      const tokens = enc.encode(messages[i]["content"]);
      if (totalTokens + tokens.length > tokenLimit) {
        break; // Exceeds token limit, so stop adding more messages
      }
      totalTokens += tokens.length;
      processed.unshift(messages[i]); // Add to the start of the processed array
    }

    console.log(totalTokens);

    return processed;
  }

  function SingleMessageDisplay(props) {
    const bgColor =
      props.message["role"] === "assistant" ? "#ebebeb" : "#f05f40";
    const fgColor =
      props.message["role"] === "assistant" ? "#000000" : "#FFFFFF";
    const textSize = props.message["role"] === "assistant" ? "normal" : "large";

    // Create a ref to the message content.
    const messageRef = useRef(null);

    // State to handle button's label.
    const [buttonLabel, setButtonLabel] = useState("Copy");

    // Function to copy the message content to the clipboard.
    const copyToClipboard = async () => {
      if (messageRef && messageRef.current) {
        try {
          // Use Clipboard API to copy text
          if (typeof ClipboardItem !== "undefined") {
            const html = new Blob([messageRef.current.innerHTML], {
              type: "text/html",
            });
            const text = new Blob([props.message["content"]], {
              type: "text/plain",
            });
            const data = new ClipboardItem({
              "text/html": html,
              "text/plain": text,
            });
            await navigator.clipboard.write([data]);
          } else {
            // Fallback using the deprecated `document.execCommand`.
            // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
            const cb = (e) => {
              e.clipboardData.setData(
                "text/html",
                messageRef.current.innerHTML
              );
              e.clipboardData.setData("text/plain", props.message["content"]);
              e.preventDefault();
            };
            document.addEventListener("copy", cb);
            document.execCommand("copy");
            document.removeEventListener("copy", cb);
          }

          // await navigator.clipboard.writeText(messageRef.current.innerHTML);
          // Change button label
          setButtonLabel("Copied!");
          // Reset button label after 1 second
          setTimeout(() => {
            setButtonLabel("Copy");
          }, 1000);
        } catch (err) {
          console.error("Failed to copy text: ", err);
        }
      }
    };

    let this_message = <></>;
    if (props.message["role"] === "assistant") {
      this_message = (
        <div ref={messageRef}>
          <Markdown remarkPlugins={[remarkBreaks]}>
            {props.message["content"]}
          </Markdown>
        </div>
      );
    } else {
      this_message = <span ref={messageRef}>{props.message["content"]}</span>;
    }

    let showAfterResult = <></>;
    if (props.message["showAfter"] >= 0) {
      showAfterResult = prompts[props.message["showAfter"]].showAfter;
    }

    return (
      <div
        style={{
          padding: "15px",
          // whiteSpace: "pre-wrap",
          backgroundColor: bgColor,
          color: fgColor,
          fontSize: textSize,
        }}
      >
        <div style={{ position: "relative", paddingBottom: "35px" }}>
          {this_message}
          {/* Show Copy button only for the assistant's messages */}
          {props.message["role"] === "assistant" && (
            <Button
              variant="outline-secondary"
              size="sm"
              style={{
                position: "absolute",
                bottom: "-5px",
                right: "10px",
              }}
              onClick={copyToClipboard}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
        {showAfterResult}
      </div>
    );
  }

  function getTextWithLineBreaks(divRef) {
    if (!divRef.current) return "";

    // Get innerHTML and replace <div>, <p>, and <br> with newlines
    let htmlContent = divRef.current.innerHTML;

    // Replacing opening and closing div or p tags with newlines
    htmlContent = htmlContent.replace(/<div>/g, "\n").replace(/<\/div>/g, "");
    htmlContent = htmlContent.replace(/<p>/g, "\n").replace(/<\/p>/g, "");

    // Replacing br tags with newlines
    htmlContent = htmlContent.replace(/<br\s*\/?>/g, "\n");

    // Create a new DOM element and set its innerHTML to the modified htmlContent
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Return the textContent of the temporary div, which now has the structure we need
    return tempDiv.textContent;
  }

  function handleStopButton(e) {
    // We can't actually stop streaming, so we just stop displaying new results
    ignoreStreamId.current = currentStreamId.current;
    currentStreamId.current = "STOP_NOW";
    setIsStreaming(false);
  }

  function removeShowAfter(objectsArray) {
    // Map over the array and return a new array where each object does not have the 'showAfter' field
    return objectsArray.map((obj) => {
      const { showAfter, ...rest } = obj; // Destructure to remove 'showAfter' and capture the rest
      return rest; // Return the new object without 'showAfter'
    });
  }

  function onSubmit(e) {
    e.preventDefault();

    if (isStreaming) {
      return;
    }

    setHasStarted(true);
    setIsStreaming(true);
    const currentShowAfter = promptNum;
    setShowAfter(promptNum);
    setLastPromptRun(promptNum);
    setPromptNum(-1);

    currentStreamId.current = null;

    let text = getTextWithLineBreaks(questionTextRef);
    let new_message = { role: "user", content: text };
    questionTextRef.current.innerHTML = "";

    let token_limit = 4050;
    if (props.gptModel in TOKEN_LIMITS) {
      token_limit = TOKEN_LIMITS[props.gptModel];
    }
    console.log("token limit: " + token_limit);

    const message_limited = limitMessagesByTokenCount(
      [...removeShowAfter(messages), new_message],
      token_limit
    );

    console.log(message_limited);

    let fetch_data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.openaiApiKey,
      },
      payload: JSON.stringify({
        model: props.gptModel,
        stream: true,
        max_tokens: 4096,
        messages: message_limited,
      }),
      // payload: JSON.stringify(truncateWrapper({
      //   model: 'gpt-4',
      //   messages: [...messages, new_message],
      // }))
    };

    askQuestion(fetch_data, new_message, 1, currentShowAfter);
  }

  function handleStreamError(e, attempt, fetch_data, new_message) {
    console.log("handleStreamError");

    let error_out = "";

    try {
      let error = JSON.parse(e.data);
      console.log(error);
      if ("error" in error && "message" in error["error"]) {
        error_out = error["error"]["message"];
      } else {
        error_out = error;
      }
    } catch {
      error_out = "failed to parse error data" + e;
    }

    console.log(`Stream error encountered. Attempt: ${attempt}`, error_out);

    // Define the maximum number of attempts
    const maxAttempts = 5;

    // Check if the maximum attempts have been reached
    if (attempt < maxAttempts) {
      // Calculate the exponential backoff delay
      const jitter = Math.floor(Math.random() * 20);
      const delay = Math.pow(2, attempt) * 200 + jitter; // Exponential backoff formula

      console.log(`Retrying in ${delay}ms...`);

      // Retry the request after the delay
      setTimeout(() => {
        askQuestion(fetch_data, new_message, attempt + 1, showAfter);
      }, delay);
    } else {
      console.log("Maximum retry attempts reached. Aborting.");
      props.setErrorMessage(error_out);
      setIsStreaming(false);
    }
  }

  function handleStreamAbort(e) {
    console.log("stream abort");
    console.log(e);
  }

  function askQuestion(fetch_data, new_message, attempt, currentShowAfter) {
    var source = new SSE(
      "https://api.openai.com/v1/chat/completions",
      fetch_data
    );

    let newMessages = [
      ...messages,
      new_message,
      { role: "assistant", content: "", showAfter: currentShowAfter },
    ];
    console.log(newMessages);
    setMessages(newMessages);

    source.addEventListener("error", (e) =>
      handleStreamError(e, attempt, fetch_data, new_message)
    );
    source.addEventListener("abort", handleStreamAbort);

    source.addEventListener("message", function (e) {
      // Assuming we receive JSON-encoded data payloads:
      if (e.data != "[DONE]") {
        let result = JSON.parse(e.data);

        if (
          currentStreamId.current == null &&
          ignoreStreamId.current != result.id
        ) {
          currentStreamId.current = result.id;
        } else if (currentStreamId.current != result.id) {
          console.log("ignorning non-current id return!");
          return;
        }

        if (result?.choices?.[0]?.delta?.content) {
          const output = result.choices[0].delta.content;

          let newMessages2 = [...newMessages];
          newMessages2[newMessages2.length - 1]["content"] =
            newMessages2[newMessages2.length - 1]["content"] + output;
          setMessages(newMessages2);
        } else if (result && !result.choices) {
          props.setErrorMessage("Error: " + result);
          setIsStreaming(false);
        }
      } else {
        setIsStreaming(false);
        saveMesssagesToLocalStorage(newMessages);
      }
    });

    console.log("starting stream");
    source.stream();
  }
}
export { Gpt };
